import {request} from "../../request";
//小区信息查询
export function communityTable(data){
  return request({
    url:'estate/selectAllEstateInfo',
    method:'get',
    params:data
  })
}
//新增小区信息
export function addCommunity(data){
  return request({
    url:'estate/addEstate',
    method:'post',
    params:data
  })
}
//查询区域所有小区名称
export function selectAllCommunity(data){
  return request({
    url:'estate/selectEstateNameAndID',
    method:'get',
    params:data
  })
}
//查询区域
export function selectAllProvinces(){
  return request({
    url:'province/selectAllProvinces',
    method:'get',
  })
}
//删除小区
export function deleteCommunity(data){
  return request({
    url:'estate/deleteEstate',
    method:'post',
    params:data
  });
}
//分页查询楼栋
export function selectAllBuilding(data){
  return request({
    url:'building/selectBuildingInfo',
    method:'get',
    params:data
  });
}
//删除楼栋
export function deleteBuildings(data){
  return request({
    url:'building/deleteBuilding',
    method:'post',
    params:data
  });
}
//楼栋类型查询
export function buildingTypeName(){
  return request({
    url:'buildingType/selectAllBuildingTypeName',
    method:'get',
  })
}
//增加楼栋信息
export function addBuilding(data){
  return request({
    url:'building/addBuilding',
    method:'post',
    params:data
  })
}
//查询所有楼栋
export function selectBuildingNameAndID(data){
  return request({
    url:'building/selectBuildingNameAndID',
    method:'get',
    params:data
  });
}
/*房屋管理*/
//已租表格
export function rentRoomInfo(data){
  return request({
    url:'room/selectAllRentRoomInfo',
    method:'get',
    params:data
  });
}
//未租表格
export function notRentRoomInfo(data){
  return request({
    url:'room/selectFreeRoom',
    method:'get',
    params:data
  });
}
//新增空闲房屋
export function addRoom(data){
  return request({
    url:'room/addRoom',
    method:'post',
    params:data
  });
}
//删除空闲房屋
export function deleteRoom(data){
  return request({
    url:'room/deleteRoom',
    method:'post',
    params:data
  });
}
//查看租赁房屋详情
export function selectRentInfo(data){
  return request({
    url:'rentRoom/selectRentInfo',
    method:'get',
    params:data
  });
}
//房屋出租
export function rentRoom(data){
  return request({
    url:'rentRoom/addRentRoom',
    method:'post',
    params:data
  });
}
//房屋用途
export function selectRoomTypes(){
  return request({
    url:'roomType/selectRoomTypes',
    method:'get',
  });
}
//查询所有用户
export function getUserNameAndPhone(){
  return request({
    url:'user/getUserNameAndPhone',
    method:'post',
  });
}
//更改租赁房屋用途
export function updateRentRoomType(data){
  return request({
    url:'rentRoom/updateRoomTypeID',
    method:'get',
    params:data
  });
}
//将房屋设置为空闲
export function setRent(data){
  return request({
    url:'rentRoom/deleteRentRoom',
    method:'get',
    params:data
  });
}