<template>
    <div class="out_container">
        <v-card elevation="1" height="810px">
            <div class="table_out">
                <!--筛选框-->
                <div class="d-flex">
                    <!--筛选-->
                    <div class="selects mr-4">
                        <div class="mr-2">筛选</div>
                        <el-select v-model="value1" placeholder="请选择">
                            <el-option
                                    v-for="item in options1"
                                    :key="item.value"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <!--小区-->
                    <div v-show="selectShow2" class="selects mr-4">
                        <div class="mr-2">小区</div>
                        <el-select v-model="value2" placeholder="请选择">
                            <el-option
                                    v-for="item in options2"
                                    :key="item.beanId"
                                    :value="item.beanId"
                                    :label="item.beanName"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <!--楼栋-->
                    <div v-show="selectShow3" class="selects mr-4">
                        <div class="mr-2">楼栋</div>
                        <el-select v-model="value3" placeholder="请选择">
                            <el-option
                                    v-for="item in options3"
                                    :key="item.beanId"
                                    :value="item.beanId"
                                    :label="item.beanName">
                            </el-option>
                        </el-select>
                    </div>
                    <!--租赁情况-->
                    <div v-show="selectShow4" class="selects mr-4">
                        <div class="mr-2">租赁情况</div>
                        <el-select v-model="value4" placeholder="请选择">
                            <el-option
                                    v-for="item in options4"
                                    :key="item.value"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                            v-show="addBtShow"
                            color="#1E88E5"
                            style="font-weight: bold"
                            dark
                            class="mt-6"
                            @click="addBt"
                    >
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                        新增
                    </v-btn>
                </div>
                <!--已租房屋表格-->
                <div v-show="houseShow" class="mt-2">
                    <el-table
                            :data="rentHouseTabMsg"
                            max-height="700"
                            style="width: 100%;height:700px;">
                        <el-table-column
                                width="50"
                                type="index"
                                label="序号">
                        </el-table-column>
                        <el-table-column
                                prop="roomName"
                                label="房屋名称">
                        </el-table-column>
                        <el-table-column
                                prop="rentStatus"
                                label="租赁情况"
                        >
                            <template slot-scope="scope">
                                <el-button type="primary" plain size="mini"
                                           @click="handleRent(scope.$index,scope.row.roomID)">已租
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="roomTypeName"
                                label="用途">
                        </el-table-column>
                        <el-table-column
                                prop="estateName"
                                label="小区">
                        </el-table-column>
                        <el-table-column
                                prop="buildingName"
                                label="楼栋">
                        </el-table-column>
                        <el-table-column
                                width="150"
                                prop="phone"
                                label="联系方式">
                        </el-table-column>
                        <el-table-column
                                prop="roomSubmissionDay"
                                label="日常报送">
                            <template slot-scope="scope">
                                <span v-if="scope.row.roomSubmissionDay === '已报送' ">
                                    <el-button type="text" style="color: #67C23A">已报送</el-button>
                                </span>
                                <span v-if="scope.row.roomSubmissionDay === '未报送' ">
                                    <el-button type="text">未报送</el-button></span>
                                <span v-if="scope.row.roomSubmissionDay === '即将截止' ">
                                    <el-button type="text" style="color: #E6A23C">即将截止</el-button>
                                </span>
                                <span v-if="scope.row.roomSubmissionDay === '已过期' ">
                                    <el-button type="text" style="color: #F56C6C">已过期</el-button>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="160"
                                prop="submissionCurrentDate"
                                label="最新报送时间">
                        </el-table-column>
                        <el-table-column
                                prop="address"
                                label="操作"
                                width="200"
                        >
                            <template slot-scope="scope">
                                <el-button type="primary" plain size="mini" @click="roomShow(scope.row)">录入</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!--未租房屋表格-->
                <div v-show="houseShow2" class="mt-2">
                    <el-table
                            :data="notRentHouseTabMsg"
                            max-height="700"
                            style="width: 100%;height:700px;">
                        <el-table-column
                                width="50"
                                type="index"
                                label="序号">
                        </el-table-column>
                        <el-table-column
                                prop="roomName"
                                label="房屋名称">
                        </el-table-column>
                        <el-table-column
                                prop="rentStatus"
                                label="租赁情况"
                        >
                            <template slot-scope="scope">
                                <el-button type="primary" plain size="mini" @click="doneRent(scope.row.roomID)">空闲
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="estateName"
                                label="小区">
                        </el-table-column>
                        <el-table-column
                                prop="buildingName"
                                label="楼栋">
                        </el-table-column>
                        <el-table-column
                                prop="roomSubmissionDay"
                                label="日常报送">
                            <template slot-scope="scope">
                <span v-if="scope.row.roomSubmissionDay === '已报送' "><el-button type="text"
                                                                               style="color: #67C23A">已报送</el-button></span>
                                <span v-if="scope.row.roomSubmissionDay === '未报送' "><el-button
                                        type="text">未报送</el-button></span>
                                <span v-if="scope.row.roomSubmissionDay === '即将截止' "><el-button type="text"
                                                                                                style="color: #E6A23C">即将截止</el-button></span>
                                <span v-if="scope.row.roomSubmissionDay === '已过期' "><el-button type="text"
                                                                                               style="color: #F56C6C">已过期</el-button></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="160"
                                prop="submissionCurrentDate"
                                label="最新报送时间">
                        </el-table-column>
                        <el-table-column
                                prop="address"
                                label="操作"
                                width="200"
                        >
                            <template slot-scope="scope">
                                <el-button type="primary" plain size="mini" @click="roomShow(scope.row)">录入</el-button>
                                <el-button type="danger" plain size="mini"
                                           @click="deleteNotRentRoom(scope.$index, scope.row.roomID)">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!--小区表格-->
                <div v-show="communityShow" class="mt-2">
                    <el-table
                            :data="communityTableMsg"
                            max-height="700"
                            style="width: 100%;height:700px;">
                        <el-table-column
                                width="50"
                                type="index"
                                label="序号">
                        </el-table-column>
                        <el-table-column
                                prop="provinceName"
                                label="所属辖区">
                        </el-table-column>
                        <el-table-column
                                prop="estateName"
                                label="小区名称">
                        </el-table-column>
                        <el-table-column
                                prop="builtTime"
                                label="建筑时间">
                        </el-table-column>
                        <el-table-column
                                prop="serviceLife"
                                label="使用年限(年)">
                        </el-table-column>
                        <el-table-column
                                prop="personalDensity"
                                label="人员密度(m^2)">
                        </el-table-column>
                        <el-table-column
                                prop="estateArea"
                                label="占地面积(m^2)">
                        </el-table-column>
                        <el-table-column
                                prop="address"
                                label="操作"
                                width="220"
                        >
                            <template slot-scope="scope">
                                <el-button type="danger" plain size="mini"
                                           @click="deleteCommunity(scope.$index, scope.row)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!--楼栋表格-->
                <div v-show="building" class="mt-2">
                    <el-table
                            :data="buildingTableMsg"
                            max-height="700"
                            style="width: 100%;height:700px;">
                        <el-table-column
                                width="50"
                                type="index"
                                label="序号">
                        </el-table-column>
                        <el-table-column
                                prop="buildingName"
                                label="楼栋名称">
                        </el-table-column>
                        <el-table-column
                                prop="residentsNumber"
                                label="居住人数">
                        </el-table-column>
                        <el-table-column
                                prop="floor"
                                label="楼层数">
                        </el-table-column>
                        <el-table-column
                                label="有无电梯">
                            <template slot-scope="scope">
                                <span>{{ scope.row.elevator === true ? '有' : '无' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="buildingTypeName"
                                label="楼栋类型">
                        </el-table-column>
                        <el-table-column
                                prop="address"
                                label="安全信息">
                            <template slot-scope="scope">
                                <el-button type="primary" plain size="mini" @click="buildingShow(scope.row)">查看
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="address"
                                label="操作"
                                width="220"
                        >
                            <template slot-scope="scope">
                                <el-button type="danger" plain size="mini"
                                           @click="deleteBuilding(scope.$index,scope.row.buildingId)">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!--表脚-->
                <!--小区-->
                <div class="table_footer" v-show="communityFooterShow">
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :page-size="10"
                            :current-page.sync="currentPage1"
                            layout="total, prev, pager, next"
                            :total=totalPage1>
                    </el-pagination>
                </div>
                <!--楼栋-->
                <div class="table_footer" v-show="buildingFooterShow">
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :page-size="10"
                            :current-page.sync="currentPage2"
                            layout="total, prev, pager, next"
                            :total=totalPage2>
                    </el-pagination>
                </div>
                <!--已租房屋-->
                <div class="table_footer" v-show="rentFooterShow">
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :page-size="10"
                            :current-page.sync="currentPage3"
                            layout="total, prev, pager, next"
                            :total=totalPage3>
                    </el-pagination>
                </div>
                <!--未租房屋-->
                <div class="table_footer" v-show="notRentFooterShow">
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :page-size="10"
                            :current-page.sync="currentPage4"
                            layout="total, prev, pager, next"
                            :total=totalPage4>
                    </el-pagination>
                </div>
            </div>
        </v-card>
        <!--弹窗-->
        <!--空闲房屋弹窗-->
        <v-dialog
                scrollable
                v-model="rentHouseDialog"
                max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">出租此房屋</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <el-form label-position="top" label-width="70px" :model="rentRoomMsg" ref="rentRoomMsgRef"
                                 :rules="rentRoomMsgRules">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <el-form-item label="承租人" prop="phone">
                                        <el-select
                                                style="width: 100%"
                                                v-model="rentRoomMsg.phone"
                                                placeholder="请输入关键字(用户名或手机账户)"
                                                filterable
                                                remote
                                                :remote-method="remoteMethod"
                                        >
                                            <el-option
                                                    v-for="item in users"
                                                    :key="item.value"
                                                    :value="item.value"
                                                    :label="item.label"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="租赁时间" prop="startDate">
                                        <el-date-picker style="width: 100%" type="date" placeholder="选择日期"
                                                        v-model="rentRoomMsg.startDate"></el-date-picker>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="到期时间" prop="endDate">
                                        <el-date-picker style="width: 100%" type="date" placeholder="选择日期"
                                                        v-model="rentRoomMsg.endDate"></el-date-picker>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="房屋用途" prop="roomTypeID">
                                        <el-select style="width: 100%" v-model="rentRoomMsg.roomTypeID"
                                                   placeholder="请选择">
                                            <el-option
                                                    v-for="item in roomTypes"
                                                    :key="item.room_type_id"
                                                    :value="item.room_type_id"
                                                    :label="item.room_type_name"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="每月上报日期" prop="submissionDate">
                                        <el-select style="width: 100%" v-model="rentRoomMsg.submissionDate"
                                                   placeholder="请选择">
                                            <el-option
                                                    v-for="item in 30"
                                                    :key="item"
                                                    :value="item">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                        </el-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="blue darken-1"
                            text
                            @click="rentHouseDialog = false"
                    >
                        取消
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="rentRoomSave"
                    >
                        提交
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--新增房屋弹窗-->
        <v-dialog
                v-model="houseDialog"
                max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">新增房屋信息</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <el-form label-position="top" label-width="70px" :model="notRentRoomMsg" ref="notRentRoomMsgRef"
                                 :rules="notRentRoomRules">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="房屋门牌" prop="roomName">
                                        <el-input v-model="notRentRoomMsg.roomName"></el-input>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="每月上报日期" prop="submissionDate">
                                        <el-select style="width: 100%" v-model="notRentRoomMsg.submissionDate"
                                                   placeholder="请选择">
                                            <el-option
                                                    v-for="item in 30"
                                                    :key="item"
                                                    :value="item">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="房屋面积(/m^2)" prop="roomArea">
                                        <el-input v-model="notRentRoomMsg.roomArea"></el-input>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="房屋高度(/m)" prop="roomHeight">
                                        <el-input v-model="notRentRoomMsg.roomHeight"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                        </el-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="blue darken-1"
                            text
                            @click="houseDialog = false"
                    >
                        取消
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="notRentRoomSave"
                    >
                        提交
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--新增小区弹窗-->
        <v-dialog
                v-model="communityDialog"
                max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">新增小区信息</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <el-form label-position="top" label-width="80px" ref="communityMsgRef" :model="communityMsg"
                                 :rules="communityMsgRules">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="所属辖区" prop="provinceCode">
                                        <el-select style="width: 100%" v-model="communityMsg.provinceCode"
                                                   placeholder="请选择">
                                            <el-option
                                                    v-for="item in allProvinces"
                                                    :key="item.provinceCode"
                                                    :label="item.provinceName"
                                                    :value="item.provinceCode">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="小区名称" prop="estateName">
                                        <el-input v-model="communityMsg.estateName"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="建筑时间" prop="builtTime">
                                        <el-date-picker style="width: 100%" type="date" placeholder="选择日期"
                                                        v-model="communityMsg.builtTime"></el-date-picker>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="使用年限(年)" prop="serviceLife">
                                        <el-input v-model="communityMsg.serviceLife"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="人口密度(个/m^2)" prop="personalDensity">
                                        <el-input v-model="communityMsg.personalDensity"></el-input>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="占地面积(m^2)" prop="estateArea">
                                        <el-input v-model="communityMsg.estateArea"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                        </el-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="blue darken-1"
                            text
                            @click="communityDialog = false"
                    >
                        取消
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="communitySave"
                    >
                        保存
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <!--新增楼栋弹窗-->
        <v-dialog
                v-model="buildingDialog"
                max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">新增楼栋信息</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <el-form label-position="top" label-width="80px" ref="buildingMsgRef" :model="buildingMsg"
                                 :rules="buildingMsgRules">
                            <v-row>

                                <v-col cols="12" md="12">
                                    <el-form-item label="楼栋名称" prop="buildingName">
                                        <el-input v-model="buildingMsg.buildingName"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="楼栋类型" prop="buildingTypeID">
                                        <el-select style="width: 100%" v-model="buildingMsg.buildingTypeID"
                                                   placeholder="请选择">
                                            <el-option
                                                    v-for="item in buildingType"
                                                    :key="item.buildingTypeID"
                                                    :label="item.buildingTypeName"
                                                    :value="item.buildingTypeID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="电梯设备" prop="elevator">
                                        <el-select style="width: 100%" v-model="buildingMsg.elevator" placeholder="请选择">
                                            <el-option
                                                    v-for="item in elevatorOption"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="楼栋层数" prop="floor">
                                        <el-input v-model="buildingMsg.floor"></el-input>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="楼栋居住人数" prop="residentsNumber">
                                        <el-input v-model="buildingMsg.residentsNumber"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                        </el-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="buildingDialog = false"
                    >
                        取消
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="buildingSave"
                    >
                        保存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--已租弹窗-->
        <v-dialog
                v-model="rentedDialogShow"
                max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">已租情况</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <el-form label-position="left" label-width="70px">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="出租方">
                                        <el-input disabled v-model="detailRentRoomMsg.landlordName"></el-input>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="承租方">
                                        <el-input disabled v-model="detailRentRoomMsg.rentUserName"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="租赁时间">
                                        <el-input disabled v-model="detailRentRoomMsg.startDate"></el-input>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="到期时间">
                                        <el-input disabled v-model="detailRentRoomMsg.endDate"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="房屋用途">
                                        <el-select style="width: 100%" v-model="detailRentRoomMsg.roomTypeName"
                                                   placeholder="请选择">
                                            <el-option
                                                    v-for="item in roomTypes"
                                                    :key="item.room_type_id"
                                                    :value="item.room_type_id"
                                                    :label="item.room_type_name"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="房屋地址">
                                        <el-input disabled v-model="detailRentRoomMsg.address"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                        </el-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="blue darken-1"
                            text
                            @click="setNotRent"
                    >
                        设为空闲
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="RentMsgSaveBt"
                    >
                        保存修改
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
    import {
        communityTable,
        addCommunity,
        selectAllCommunity,
        selectAllProvinces,
        selectBuildingNameAndID,
        deleteCommunity,
        buildingTypeName,
        addBuilding,
        deleteBuildings,
        selectAllBuilding,
        rentRoomInfo,
        notRentRoomInfo,
        addRoom,
        deleteRoom,
        selectRentInfo,
        rentRoom,
        selectRoomTypes,
        getUserNameAndPhone
    } from "network/Details/general_page/houseManage"
    import {setRent, updateRentRoomType} from "../../network/Details/general_page/houseManage";

    var util = require('assets/js/util.js');
    export default {
        name: "HouseManage",
        created() {
            //所有房屋类型
            selectRoomTypes().then(res => {
                if (res.status === 200) {
                    this.roomTypes = res.data;
                }
            });
            //获取所有区域
            selectAllProvinces().then(res => {
                this.updateRentHouseTab();
                this.allProvinces = res.data;
                /*      console.log(res.data);
                      console.log(res.data.length)
                      if(res.data.length === 1){
                        this.provinceCode = res.data[0].provinceCode;
                        this.provinceName = res.data[0].provinceName;
                        this.roleFlag = 0;
                      }else {
                        this.roleFlag = 1;
                      }*/
            });
            //获取所有房屋类型
            buildingTypeName().then(res => {
                this.buildingType = res.data;
            });
        },
        methods: {
            //房屋报送信息跳转
            roomShow(row) {
                let url = this.$router.resolve({
                    path: "/DailyManage",
                    query: {
                        roomId: row.roomID
                    }
                })
                window.open(url.href, "_blank")
            },
            //楼栋安全信息录入跳转
            buildingShow(row) {
                console.log(row)
                let url = this.$router.resolve({
                    path: "/HouseSafetyInfo",
                    query: {
                        buildingId: row.buildingId
                    }
                })
                console.log(url.href)
                window.open(url.href, "_blank")
            },
            //关键字查询用户
            remoteMethod(query) {
                if (query !== '') {
                    this.users = this.userList.filter(item => {
                        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                } else {
                    this.users = [];
                }
            },
            //出租房屋
            doneRent(roomId) {
                this.$nextTick(() => {
                    if (this.$refs.rentRoomMsgRef !== undefined)
                        this.$refs.rentRoomMsgRef.resetFields();
                });
                getUserNameAndPhone().then(res => {
                    this.userList = res.data.map(item => {
                        let s1 = item.split('-')[0];
                        let s2 = item.split('-')[1];
                        return {value: `${s2}`, label: `用户名: ${s1}; 手机账户: ${s2}`};
                    });
                });
                this.tempRoomId = roomId;
                this.rentHouseDialog = true;
            },
            //空闲房屋出租(保存)
            rentRoomSave() {
                let rentMsg = this.rentRoomMsg;
                rentMsg.startDate = util.formatTime2(rentMsg.startDate);
                rentMsg.endDate = util.formatTime2(rentMsg.endDate);
                rentMsg.rentRoomID = this.tempRoomId;
                rentMsg.provinceCode = this.provinceCode;
                rentMsg.roomOutID = this.userId;
                rentRoom(rentMsg).then(res => {
                    if (res.status === 200) {
                        this.notRentHouseTabMsg.splice(this.tempIndex, 1);
                        this.$message.success("出租此房屋成功！");
                    } else {
                        util.warningMsg(res.status + " : " + res.desc)
                    }
                });
                this.rentHouseDialog = false;
            },
            //保存增加的空闲房屋信息
            notRentRoomSave() {
                let room = this.notRentRoomMsg;
                this.$refs.notRentRoomMsgRef.validate((valid) => {
                    if (valid) {
                        room.estateID = this.value2;
                        room.buildingID = this.value3;
                        addRoom(room).then(res => {
                            if (res.status === 200) {
                                this.updateNotRentHouseTab();
                                this.houseDialog = false;
                            }
                        });
                    } else {
                        return false
                    }
                });
            },
            //保存增加的楼栋信息
            buildingSave() {
                let buildingMsg = this.buildingMsg;
                this.$refs.buildingMsgRef.validate((valid) => {
                    if (valid) {
                        buildingMsg.estateID = this.value2;
                        buildingMsg.provinceCode = this.provinceCode;
                        addBuilding(buildingMsg).then(res => {
                            if (res.status === 200) {
                                this.updateBuildings();
                                if (this.buildingTableMsg.length < 10) {
                                    buildingMsg.buildingId = res.data.buildingID;
                                    try {
                                        this.buildingType.forEach(v => {
                                            if (v.buildingTypeID === buildingMsg.buildingTypeID) {
                                                buildingMsg.buildingTypeName = v.buildingTypeName;
                                                throw new Error("END");
                                            }
                                        });
                                    } catch (e) {
                                        if (e.message != "END") throw e;
                                    }
                                    buildingMsg = JSON.parse(JSON.stringify(buildingMsg));
                                    this.buildingTableMsg.push(buildingMsg);
                                }
                                this.totalPage2++;
                                this.$message.success("增加楼栋信息成功！");
                            }
                        });
                        this.buildingDialog = false;
                    } else {
                        return false;
                    }
                });
            },
            //保存增加的小区信息
            communitySave() {
                let communityMsg = this.communityMsg;
                this.$refs.communityMsgRef.validate((valid) => {
                    if (valid) {
                        communityMsg.builtTime = util.formatTime2(communityMsg.builtTime);
                        addCommunity(communityMsg).then(res => {
                            if (res.status === 200) {
                                this.updateAllCommunity();
                                if (this.communityTableMsg.length < 10) {
                                    try {
                                        this.allProvinces.forEach(v => {
                                            if (v.provinceCode === communityMsg.provinceCode) {
                                                communityMsg.provinceName = v.provinceName;
                                                throw new Error("END");
                                            }
                                        });
                                    } catch (e) {
                                        if (e.message != "END") throw e;
                                    }
                                    communityMsg.estateID = res.data;
                                    communityMsg = JSON.parse(JSON.stringify(communityMsg));
                                    this.communityTableMsg.push(communityMsg);
                                }
                                this.totalPage1++;
                                this.$message.success("增加小区信息成功！");
                            }
                        });
                        this.communityDialog = false;
                    } else {
                        return false;
                    }
                });
            },
            handleCurrentChange() {
                let flag = this.updateFooterFlag;
                if (flag === 1) {
                    this.updateCommunityTable();
                    console.log(1);
                } else if (flag === 2) {
                    this.updateBuildingTab();
                    console.log(2)
                } else if (flag === 3) {
                    this.updateRentHouseTab();
                    console.log(3)
                } else {
                    this.updateNotRentHouseTab();
                    console.log(4)
                }
            },
            //小区筛选框
            updateAllCommunity() {
                selectAllCommunity({
                    provinceCode: this.provinceCode
                }).then(res => {
                    this.options2 = res.data;
                    console.log(this.options2)
                })
            },
            //小区表格数据
            updateCommunityTable() {
                communityTable({
                    pageNum: this.currentPage1,
                    pageSize: this.pageSize1,
                    provinceCode: this.provinceCode
                }).then(res => {
                    if (res.status === 200) {
                        this.communityTableMsg = res.data.info;
                        this.totalPage1 = res.data.total;
                    } else {
                        util.warningMsg(res.status + " : " + res.desc)
                    }
                });
            },
            //楼栋表格数据
            updateBuildingTab() {
                selectAllBuilding({
                    estateID: this.value2,
                    pageNum: this.currentPage2,
                    pageSize: this.pageSize2
                }).then(res => {
                    this.totalPage2 = res.data.total;
                    this.buildingTableMsg = res.data.info;
                });
            },
            //楼栋筛选框
            updateBuildings() {
                selectBuildingNameAndID({
                    estateID: this.value2
                }).then(res => {
                    this.options3 = res.data;
                })
            },
            //删除空闲
            deleteNotRentRoom(index, roomID) {
                this.$confirm('此操作将永久删除该房屋信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteRoom({
                        roomID
                    }).then(res => {
                        if (res.status === 200) {
                            this.notRentHouseTabMsg.splice(index, 1);
                            if (this.notRentHouseTabMsg.length === 0) {
                                this.updateNotRentHouseTab();
                            }
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //删除楼栋信息
            deleteBuilding(index, buildingId) {
                this.$confirm('此操作将永久删除该楼栋信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteBuildings({
                        buildingID: buildingId,
                        estateID: this.value2
                    }).then(res => {
                        if (res.status === 200) {
                            this.updateBuildings();
                            this.buildingTableMsg.splice(index, 1);
                            if (this.buildingTableMsg.length === 0) {
                                this.updateBuildingTab();
                            }
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //删除小区弹框
            deleteCommunity(index, row) {
                let {estateID} = row;
                this.$confirm('此操作将永久删除该小区信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteCommunity({
                        estateID
                    }).then(res => {
                        if (res.status === 200) {
                            this.updateAllCommunity();
                            this.communityTableMsg.splice(index, 1);
                            if (this.communityTableMsg.length === 0) {
                                this.updateCommunityTable();
                            }
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //增加按钮
            //已租房屋表格
            updateRentHouseTab() {
                rentRoomInfo({
                    estateID: this.value2,
                    buildingID: this.value3,
                    pageNum: this.currentPage3,
                    pageSize: this.pageSize3,
                    provinceCode: this.provinceCode
                }).then(res => {
                    if (res.status === 200) {
                        let {info} = res.data;
                        info.forEach(v => {
                            if (v.submissionCurrentDate === null) {
                                v.roomSubmissionDay = "未报送";
                                v.submissionCurrentDate = "暂无";
                            } else {
                                let time = new Date(v.submissionCurrentDate);
                                v.submissionCurrentDate = util.formatTime(time);
                                v.roomSubmissionDay = this.handleInfoSub(time, v.roomSubmissionDay);
                            }
                        });
                        this.rentHouseTabMsg = info;
                        this.totalPage3 = res.data.total;
                    }
                });
            },
            //未租房屋
            updateNotRentHouseTab() {
                notRentRoomInfo({
                    estateID: this.value2,
                    buildingID: this.value3,
                    pageNum: this.currentPage4,
                    pageSize: this.pageSize4,
                    provinceCode: this.provinceCode
                }).then(res => {
                    if (res.status === 200) {
                        let {info} = res.data;
                        info.forEach(v => {
                            if (v.submissionCurrentDate === null) {
                                v.submissionCurrentDate = '暂无';
                                v.roomSubmissionDay = '未报送';
                            } else {
                                let time = new Date(v.submissionCurrentDate);
                                v.submissionCurrentDate = util.formatTime(time);
                                v.roomSubmissionDay = this.handleInfoSub(time, v.roomSubmissionDay);
                            }
                        });
                        this.notRentHouseTabMsg = info;
                        this.totalPage4 = res.data.total;
                    }
                });
            },
            //已租弹窗按钮
            handleRent(index, roomID) {
                this.tempRoomId = roomID;
                this.tempIndex = index;
                selectRentInfo({
                    roomID
                }).then(res => {
                    this.detailRentRoomMsg = res.data;
                });
                this.rentedDialogShow = true;
            },
            //增加按钮
            addBt() {
                let flag = this.addDialogFlag;
                if (flag === 1) {
                    this.houseDialog = true;
                    this.$nextTick(() => {
                        this.$refs.notRentRoomMsgRef.resetFields();
                    });
                } else if (flag === 2) {
                    this.communityDialog = true;
                    this.$nextTick(() => {
                        this.$refs.communityMsgRef.resetFields();
                    });
                } else {
                    this.buildingDialog = true;
                    this.$nextTick(() => {
                        this.$refs.buildingMsgRef.resetFields();
                    });
                }
            },
            //保存房屋出租信息
            RentMsgSaveBt() {
                updateRentRoomType({
                    roomID: this.tempRoomId,
                    roomTypeID: this.detailRentRoomMsg.roomTypeName
                }).then(res => {
                    if (res.status === 200) {
                        try {
                            this.roomTypes.forEach(v => {
                                if (v.room_type_id === this.detailRentRoomMsg.roomTypeName) {
                                    this.rentHouseTabMsg[this.tempIndex].roomTypeName = v.room_type_name;
                                    throw new Error("END");
                                }
                            });
                        } catch (e) {
                            if (e.message != "END") throw e;
                        }
                        this.$message.success("修改成功！");
                    }
                });
                this.rentedDialogShow = false
            },
            //设为空闲
            setNotRent() {
                setRent({
                    roomID: this.tempRoomId
                }).then(res => {
                    if (res.status === 200) {
                        this.$message.success("设为空闲成功！");
                        this.rentHouseTabMsg.splice(this.tempIndex, 1);
                    }
                });
                this.rentedDialogShow = false;
            },
            //处理信息报送
            handleInfoSub(date, subDay) {
                //date:上次上报时间; subDay：开始上报时间
                const reportingDay = 7 //上报区间:7天
                const endDay = 2; //即将截止:2天;
                let nowDate = new Date();
                if (date.getMonth() === nowDate.getMonth()) {
                    return '已报送';
                } else {
                    if (nowDate.getDate() < subDay) {
                        return '已报送';
                    } else {
                        let tempDay = nowDate.getDate() - subDay;
                        if (tempDay <= (reportingDay - endDay)) {
                            return '未报送';
                        } else {
                            if (tempDay <= reportingDay) {
                                return '即将截止';
                            } else {
                                return '已过期';
                            }
                        }
                    }
                }
            },
        },
        watch: {
            //筛选
            value1(newVal) {
                if (newVal === '层级') {
                    this.houseShow = false;
                    this.houseShow2 = false;
                    this.updateCommunityTable();
                    this.updateAllCommunity();
                    this.communityShow = true;
                    this.updateFooterFlag = 1;
                    this.totalPage = this.totalPage1;
                    this.pageSize = this.pageSize1;
                    this.currentPage = this.currentPage1;
                    this.buildingFooterShow = false;
                    this.rentFooterShow = false;
                    this.communityFooterShow = true;
                    this.addDialogFlag = 2;
                    this.addBtShow = true;
                    this.selectShow2 = true;
                    this.selectShow4 = false;
                    this.value2 = '';
                    this.value3 = '';
                }
                if (newVal === '所有房屋') {
                    this.value2 = '';
                    this.value3 = '';
                    this.houseShow2 = false;
                    this.updateFooterFlag = 3,
                        this.totalPage = this.totalPage3;
                    this.pageSize = this.pageSize3;
                    this.currentPage = this.currentPage3;
                    this.notRentFooterShow = false;
                    this.communityFooterShow = false;
                    this.buildingFooterShow = false;
                    this.rentFooterShow = true;
                    this.addDialogFlag = 1;
                    this.addBtShow = false;
                    this.selectShow2 = false;
                    this.selectShow3 = false;
                    this.selectShow4 = true;
                    this.houseShow = true;
                    this.communityShow = false;
                    this.building = false;
                    this.value4 = '已租'
                    this.updateRentHouseTab();
                }
            },
            //小区
            value2(newVal) {
                if (newVal !== '') {
                    this.updateBuildingTab();
                    this.updateBuildings();
                    this.updateFooterFlag = 2;
                    this.totalPage = this.totalPage2;
                    this.pageSize = this.pageSize2;
                    this.currentPage = this.currentPage2;
                    this.notRentFooterShow = false;
                    this.rentFooterShow = false;
                    this.communityFooterShow = false;
                    this.buildingFooterShow = true;
                    this.addDialogFlag = 3;
                    this.selectShow3 = true;
                    this.selectShow4 = false;
                    this.building = true;
                    this.communityShow = false;
                    this.houseShow = false;
                    this.houseShow2 = false;
                    this.value3 = '';
                }
            },
            //楼栋
            value3(newVal) {
                if (newVal !== '') {
                    this.updateRentHouseTab();
                    this.updateFooterFlag = 3;
                    this.totalPage = this.totalPage3;
                    this.pageSize = this.pageSize3;
                    this.currentPage = this.currentPage3;
                    this.buildingFooterShow = false;
                    this.rentFooterShow = true;
                    this.addDialogFlag = 1;
                    this.selectShow4 = true;
                    this.houseShow = true;
                    this.building = false;
                    this.value4 = '已租';
                    this.addBtShow = false;
                }
            },
            //租赁情况
            value4(newVal) {
                if (newVal === '已租') {
                    this.updateFooterFlag = 3;
                    this.totalPage = this.totalPage3;
                    this.pageSize = this.pageSize3;
                    this.currentPage = this.currentPage3;
                    this.notRentFooterShow = false;
                    this.rentFooterShow = true;
                    this.updateRentHouseTab();
                    this.addBtShow = false;
                    this.houseShow = true;
                    this.houseShow2 = false;
                }
                if (newVal === '未租') {
                    this.updateFooterFlag = 4;
                    this.totalPage = this.totalPage4;
                    this.pageSize = this.pageSize4;
                    this.currentPage = this.currentPage4;
                    this.rentFooterShow = false;
                    this.notRentFooterShow = true;
                    this.houseShow = false;
                    this.houseShow2 = true;
                    this.updateNotRentHouseTab();
                    if (this.value1 === '层级') {
                        this.addBtShow = true;
                    }
                }
            },
        },
        data() {
            return {
                //更新页脚的标志
                updateFooterFlag: 3,
                //出租房屋详情
                detailRentRoomMsg: {},
                //用户id
                userId: window.localStorage.getItem("userId"),
                //临时index
                tempIndex: 0,
                //临时roomId
                tempRoomId: '',
                //用户列表
                userList: [],
                users: [],
                //区域id
                provinceCode: '',
                provinceName: '',
                //房屋类型
                roomTypes: [],
                // 楼栋电梯选择
                elevatorOption: [
                    {
                        value: true,
                        label: '有'
                    },
                    {
                        value: false,
                        label: '无'
                    },
                ],
                // 新增楼栋信息
                buildingMsg: {
                    buildingName: '',
                    buildingTypeID: '',
                    elevator: '',
                    estateID: '',
                    floor: '',
                    provinceCode: '',
                    residentsNumber: '',
                },
                //新增租赁房屋
                rentRoomMsg: {
                    endDate: '',
                    phone: '',
                    roomTypeID: '',
                    startDate: '',
                    submissionDate: ''
                },
                //楼栋类型
                buildingType: '',
                //楼栋：
                buildingTableMsg: [],
                //已租房屋表格
                rentHouseTabMsg: [],
                //未租房屋表格
                notRentHouseTabMsg: [],
                houseShow2: false,
                //所有区域
                allProvinces: [],
                //临时
                tempValue: '',
                //页脚显示
                communityFooterShow: false,
                buildingFooterShow: false,
                rentFooterShow: true,
                notRentFooterShow: false,
                //页脚
                totalPage: 1,
                pageSize: 10,
                currentPage: 1,
                //小区
                totalPage1: 1,
                pageSize1: 10,
                currentPage1: 1,
                //楼栋
                totalPage2: 1,
                pageSize2: 10,
                currentPage2: 1,
                //已租房屋
                totalPage3: 1,
                pageSize3: 10,
                currentPage3: 1,
                //未租房屋
                totalPage4: 1,
                pageSize4: 10,
                currentPage4: 1,
                //小区表格
                communityTableMsg: [],
                rentedDialogShow: false,
                idleDialogShow: false,
                addBtShow: false,
                addDialogFlag: 1,
                rentHouseDialog: false,
                houseDialog: false,
                communityDialog: false,
                buildingDialog: false,
                houseShow: true,
                communityShow: false,
                building: false,
                selectShow2: false,
                selectShow3: false,
                selectShow4: true,
                //小区信息(新增)
                communityMsg: {
                    builtTime: '',
                    estateArea: '',
                    estateName: '',
                    personalDensity: '',
                    provinceCode: '',
                    serviceLife: ''
                },
                //空闲房屋(新增)
                notRentRoomMsg: {
                    submissionDate: '',
                    roomName: '',
                    roomArea: '',
                    roomHeight: ''
                },
                //出租房屋表单验证
                rentRoomMsgRules: {
                    endDate: [{required: true, message: '请选择到期时间', trigger: 'blur'},],
                    phone: [
                        {required: true, message: '请输入承租用户', trigger: 'blur'},
                    ],
                    startDate: [
                        {required: true, message: '请选择出租时间', trigger: 'blur'},
                    ],
                    roomTypeID: [
                        {required: true, message: '请选择房屋用途', trigger: 'blur'},
                    ],
                    submissionDate: [
                        {required: true, message: '请选择没有上报日期', trigger: 'blur'},
                    ],
                },
                //小区信息(新增) 表单验证
                communityMsgRules: {
                    builtTime: [
                        {required: true, message: '请输入建筑时间', trigger: 'blur'},
                    ],
                    estateArea: [
                        {required: true, message: '请输入占地面积', trigger: 'blur'},
                    ],
                    estateName: [
                        {required: true, message: '请输入小区名称', trigger: 'blur'},
                    ],
                    personalDensity: [
                        {required: true, message: '请输入人口密度', trigger: 'blur'},
                    ],
                    provinceCode: [
                        {required: true, message: '请选择所属辖区', trigger: 'blur'},
                    ],
                    serviceLife: [
                        {required: true, message: '请输入使用年限', trigger: 'blur'},
                    ],
                },
                //新增楼栋校验
                buildingMsgRules: {
                    buildingID: [
                        {required: true, message: '请输入楼栋编号', trigger: 'blur'},
                    ],
                    buildingName: [
                        {required: true, message: '请输入楼栋名称', trigger: 'blur'},
                    ],
                    buildingTypeID: [
                        {required: true, message: '请选择楼栋类型', trigger: 'blur'},
                    ],
                    elevator: [
                        {required: true, message: '请选择电梯配置情况', trigger: 'blur'},
                    ],
                    floor: [
                        {required: true, message: '请输入楼栋层数', trigger: 'blur'},
                    ],
                    residentsNumber: [
                        {required: true, message: '楼栋居住人数', trigger: 'blur'},
                    ],
                },
                //新增空闲房屋表单验证
                notRentRoomRules: {
                    roomName: [
                        {required: true, message: '请输入房屋门牌号', trigger: 'blur'},
                    ],
                    submissionDate: [
                        {required: true, message: '请选择所属辖区', trigger: 'blur'},
                    ],
                    roomArea: [
                        {required: true, message: '请输入房屋面积', trigger: 'blur'},
                    ],
                    roomHeight: [
                        {required: true, message: '请输入房屋高度', trigger: 'blur'},
                    ],
                },
                //筛选框数据
                //筛选
                value1: '所有房屋',
                options1: [{value: '所有房屋',}, {value: '层级',}],
                //小区
                value2: '',
                options2: [],
                //楼栋
                value3: '',
                options3: [],
                //租赁情况
                value4: '已租',
                options4: [
                    {
                        value: '已租',
                    }, {
                        value: '未租',
                    }],
            }
        }
    }
</script>

<style lang="less" scoped>
    .out_container {
        position: relative;
        margin-top: 1%;
        width: 98%;
        margin-left: 1%;

        .table_out {
            width: 96%;
            margin-left: 3%;

            .selects {
                padding-top: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .table_footer {
                position: absolute;
                bottom: 5px;
                right: 5px;
            }
        }
    }
</style>
